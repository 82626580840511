import { Typography } from "@mui/material";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

export function Home() {
   return (
      <div
         className="App"
         style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "darkslateblue",
            color: "white"
         }}
      >
         <Typography variant="h1" component="h2">
            My Unit Conversion
         </Typography>
      </div>
   );
}
